import React from "react"

// import Layout from "../components/layout"
import SEO from "../components/seo"
import rond_color from "../assets/rond_color.svg"
// import { Link } from "gatsby"

import Navigation from "../components/Navigation"


const bonnePersonne = () => (
    <div
        className="containerManifesto"
      >
        <div className=" container containerHome">
        <Navigation/>
        {/*<div className="rondTache row">
          <img src={rond} className="col-3 rond"/>
          <img src={tache} className="col-9 tache"/>
        </div>*/}
    <div>
    <SEO title="Plaiz - Tu es une bonne personne - Plaiz, le réseau social de la mode"/>
    <div>
    <h1 className="p1" style={{color:"white", marginTop:100}}>Tu es une bonne personne ✨</h1>

    <h4
      style={styles.backToHomeLink}
    >
    <p className="textChangeGameLeft"><br/>
          <b></b>
            <br/>
            En téléchargeant Plaiz, tu contribues à un monde un peu meilleur ! Notre but est d'aider 1 milliard de personnes à vivre de leur passion.
            <br/><br/>
            On a été la première plateforme au monde à se débarasser des compteurs de likes pour que vous n'ayez plus à subir cette pression et puissiez vous exprimer librement, de manière authentique.
            <br/><br/>
            Ça a été une révolution et depuis, nous nous sommes efforcés d'améliorer Plaiz dans ce sens.
            <br/><br/>
            Par exemple, nous cachons les dates de publication sur les profils pour qu'un nouvel arrivant puisse rapidement poster énormément de photos et compléter son univers sans qu'on ait l'impression qu'il a débarqué hier sur l'application et ressente une gêne par rapport aux autres utilisateurs présents depuis plus longtemps.
            <br/><br/>
            Nous avons 3 types de réactions car les utilisateurs qui postent du contenu peuvent connaître le nombre de réactions qu'ils ont obtenu, en guise d'avis (uniquement positif). Le fait d'avoir 3 réactions permet de se décharger de la charge mentale qui nous pèse lorsqu'on compare le nombre de likes qu'on a eu sur des photos. Vu qu'il est difficile de savoir si 3👏, 5💚 et 8🔥 valent mieux que 5👏, 10💚 et 3🔥, nos utilisateurs choisissent de ne pas faire le calcul, et postent sans se poser la question.
            <br/><br/>
            Nous sommes conscients que ces procédés vont ralentir notre croissance et réduire le temps passé sur l'application vu que nous ne jouons pas sur les aspect psychologique utilisés par les autres réseaux. Mais nous préférons faire le bien. Et c'est pour cela que nous sommes encore une société indépendante, tant que nous n'aurons pas de partenaires qui partagent nos valeurs, et que nous ne monétisons pas pour l'instant la plateforme.
            <br/><br/>
            Nous rendons publiques ces informations dans l'espoir d'inspirer d'autres plateformes, c'est comme cela que nous impacterons 1 milliard de personnes. #TeamGameChanger
            <br/><br/>
            Pour le reste, nous vous laissons découvrir nos autres surprises sur l'application ! Surtout, amusez-vous bien !
            <br/><br/>
            (Et si vous êtes arrivés jusqu'ici, dites-le nous :) hello@plaiz.io)
            <br/><br/><br/><br/><br/>

        </p>
        
    </h4>
    </div>
    </div>
    </div>
    </div>
)

export default bonnePersonne

const styles = {
  backToHomeLink: {
    color: "white",
  },
}